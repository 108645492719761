<template>
  <div class="body">
    <div class="home">
      <img class="hedaer-image" src="../../assets/dashboard/diannao2.png" alt="">
      <div
        v-if="patientDict && patientDict.xm"
        class="patient_card"
        @click="routeTo('/patientList',1,true)"
      >
        <div class="card_name">
          <div>{{ patientDict.xm }}</div>
          <div class="name_span">
            {{ patientDict.xbmc }}
          </div>
          <div class="name_span">
            {{ patientDict.age }}岁
          </div>
          <div class="card-current">
            当前使用
          </div>
        </div>
        <van-popover
          v-model="changeCardshowPopover"
          theme="light"
          trigger="click"
        >
          <div class="card-list">
            <div v-for="(item,index) in changeCardActions" :key="index" class="card-list-item" @click="changeCardAction(item)">
              {{ item.jzklxmc }}<span class="ml-24">{{ item.jzkh }}</span>
            </div>
          </div>
          <template #reference>
            <div
              v-if="
                patientDict.patientCardList &&
                  patientDict.patientCardList.length > 0
              "
              class="card_number"
              @click.stop="changeCardShowAction"
            >
              <span>{{ patientDict.patientCardList[0].jzklxmc }}</span> 
              <span class="card_span">{{ patientDict.patientCardList[0].jzkh }}</span> 
              <van-icon
                v-if="patientDict.patientCardList.length > 1"
                class="number"
                name="arrow-down"
                color="#4f4f4f"
                size="21"
              />
            </div>
          </template>
        </van-popover>
        <div class="card_change">
          <img src="../../assets/dashboard/qiehuan@2x.png" alt="" style="right: 105%;top: 12%;width: 20px;height: 18px;">
          <div>切换就诊人</div> 
        </div>
      </div>
      <div v-else class="add-button" @click="routeTo('/patientList',1,true)">
        <img src="../../assets/img/add_hollow.png">
        添加就诊人
      </div>
      <!-- <div class="payment-tips">
        <span>当前平台仅支持自费就诊，医保暂未开通，无法支付。</span>
      </div> -->
      
      <div class="home-in">
        <div class="entry-list">
          <div class="entry-item" @click="routeTo('/seleteDept', 1, true)">
            <img class="img" :src="require('../../assets/dashboard/yygh.png')">
            <div>预约挂号</div>
          </div>
          <div class="entry-item" @click="getSignInQueueDetailData">
            <img class="img" :src="require('../../assets/dashboard/bianzu6.png')">
            <div>签到/候诊</div>
          </div>
          <div class="entry-item" @click="routeTo('/payFees', 1, true)">
            <img class="img" :src="require('../../assets/dashboard/bianzu7.png')">
            <div>在线缴费</div>
          </div>
          <div class="entry-item" @click="gotoDzfp">
            <img class="img" :src="require('../../assets/dashboard/bianzu4.png')">
            <div>电子发票</div>
          </div>
          <!-- <div class="entry-item" @click="routerToHome('/home')">
            <img class="img" :src="require('../../assets/dashboard/bianzu4.png')">
            <div>互联网医院</div>
          </div> -->
        </div>
        <div v-if="showMessage" class="home_message">
          <img src="../../assets/dashboard/dytx.png" alt="" class="message-image">
          <van-notice-bar
            color="#1989fa"
            background="#fff"
            :scrollable="false"
            class="message_count"
          >
            <van-swipe
              vertical
              class="notice-swipe"
              :autoplay="3000"
              :show-indicators="false"
            >
              <div
                v-for="(item, index) in msgList"
                :key="index"
              >
                <van-swipe-item class="swipe_text" @click="getMsgDetail(item)">
                  {{ item.xxnr }}
                </van-swipe-item>
              </div>
            </van-swipe>
          </van-notice-bar>
          <!-- <van-icon
          class="swipe_right_icon"
          name="notes-o"
          color="#333"
          size="20"
          
        /> -->
        </div>
        <div>
          <div class="card-box">
            <div class="card-title">
              门诊服务
            </div>
            <div class="card-name">
              <div
                v-for="item in outpatientServe"
                :key="item.name"
                class="item"
                @click="routeTo(item.url, item.type, item.require)"
              >
                <img class="img" :src="item.imgUrl">
                <div class="txt">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card-box">
            <div class="card-title">
              住院服务
            </div>
            <div class="card-name">
              <div
                v-for="item in hospServe"
                :key="item.name"
                class="item"
                @click="routeTo(item.url, item.type, item.require)"
              >
                <img class="img" :src="item.imgUrl">
                <div class="txt">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card-box">
            <div class="card-title">
              在线诊疗
            </div>
            <div class="card-name">
              <div
                v-for="item in onlineServe"
                :key="item.name"
                class="item"
                @click="onClickHref(item.type)"
              >
                <img class="img" :src="item.imgUrl">
                <div class="txt">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card-box">
            <div class="card-title">
              体检服务
            </div>
            <div class="card-name">
              <div
                v-for="item in physicalServe"
                :key="item.name"
                class="item"
                @click="routeTo(item.url, item.type, item.require)"
              >
                <img class="img" :src="item.imgUrl">
                <div class="txt">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card-box">
            <div class="card-title">
              医院信息
            </div>
            <div class="card-name">
              <div
                v-for="item in hospDetail"
                :key="item.name"
                class="item"
                @click="routeTo(item.url, '', item.require)"
              >
                <img class="img" :src="item.imgUrl">
                <div class="txt">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gjcModel } from "@/api/gjcApi"
import { setItem } from "@/utils/cache"
import { isEmptyObject } from '../../utils'

export default {
  name:'GjcMain',
  data() {
    return {
      patientDict: {}, //就诊人信息
      changeCardshowPopover: false,
      changeCardActions: [],
      msgList: [], //消息数组
      timer: null, //定时器
      patientList: [], //就诊人列表数据
      showMessage:true, //是否显示消息
      outpatientServe: [
        {
          name: "预约记录",
          url: "/appointList",
          imgUrl: require("../../assets/dashboard/yyjl.png"),
          require: true
        },
        {
          name: "电子处方",
          url: "/gjcPrescription",
          imgUrl: require("../../assets/dashboard/dzcf.png"),
          require: true
        },
        {
          name: "门诊报告",
          url: "/reportList",
          type: "1",
          imgUrl: require("../../assets/dashboard/dzcf1.png"),
          require: true
        },
        {
          name: "费用明细",
          url: "/costList",
          imgUrl: require("../../assets/dashboard/fymx.png"),
          require: true
        }
      ],
      hospServe: [
        // {
        //   name: '预约住院',
        //   url: '/myAppointment',
        //   imgUrl: require('../../assets/dashboard/yyzy.png')
        // },
        {
          name: "住院缴费",
          url: "/payFees",
          type: "3",
          imgUrl: require("../../assets/dashboard/zyjf.png"),
          require: true
        },
        {
          name: "住院报告",
          url: "/reportList",
          type: "2",
          imgUrl: require("../../assets/dashboard/zybg.png"),
          require: true
        },
        {
          name: "一日清单",
          url: "/dayList",
          imgUrl: require("../../assets/dashboard/yrqd.png"),
          require: true
        }
      ],
      onlineServe: [
        {
          name: '蓝生脑医',
          url: ' https://m.naokehealth.cn/',
          imgUrl: require('../../assets/dashboard/lsny.png'),
          type:'2'
        },
        {
          name: "蓝生商城",
          url: "https://m-mall.naokehealth.cn/",
          imgUrl: require("../../assets/dashboard/lssc.png"),
          type:'1'
        }
        // {
        //   name: "住院报告",
        //   url: "/reportList",
        //   type: "2",
        //   imgUrl: require("../../assets/dashboard/zybg.png"),
        //   require: true
        // }
      ],
      physicalServe: [
        // {
        //   name: '预约体检',
        //   url: '/myAppointment',
        //   imgUrl: require('../../assets/dashboard/yytj.png')
        // },
        // {
        //   name: '体检缴费',
        //   url: '/myRegister',
        //   imgUrl: require('../../assets/dashboard/tjjf.png')
        // },
        {
          name: "体检报告",
          url: "/reportList",
          type: "3",
          imgUrl: require("../../assets/dashboard/tjbg.png"),
          require: false
        }
      ],
      hospDetail: [
        {
          name: "医院介绍",
          url: "/gjcHosp",
          imgUrl: require("../../assets/dashboard/yyjs.png"),
          require: false
        },
        {
          name: "科室介绍",
          url: "/deptList",
          imgUrl: require("../../assets/dashboard/ksjs.png"),
          require: false
        },
        {
          name: "院内导航",
          url: "/inNav",
          imgUrl: require("../../assets/dashboard/yndh.png"),
          require: false
        },
        {
          name: "价格公示",
          url: "/priceList",
          imgUrl: require("../../assets/dashboard/jggs.png"),
          require: false
        },
        {
          name: "来院路线",
          url: "/gjcHospPath",
          imgUrl: require("../../assets/dashboard/lylx.png"),
          require: false
        },
        // {
        //   name: "满意度调查",
        //   url: "/consultation",
        //   imgUrl: require("../../assets/dashboard/myddc.png"),
        //   require: false
        // },
        {
          name: '账号设置',
          url: '/accountSettings',
          imgUrl: require('../../assets/dashboard/zhsz.png'),
          require: false
        }
      ]
    }
  },
  created() {
  },
  activated() {
    if (this.$store.getters.token) { 
      this.getHomePatientListData()
      this.getQueryNewestMsgData()
      this.getTimerData()
      // this.getHomePatientListData()
    }else{
      this.patientDict = {}
      this.changeCardActions = []
      this.$store.commit("user/setPatient", '')
      this.showMessage=  false
    }
    //签到刷新页面标识
    setItem("updatePage",1)
    //预约须知弹窗标识
    setItem("yyxzStatus",1)
    //
    this.$store.commit("user/setWxLogin", 1)
  },
  deactivated() {
    clearInterval(this.timer)
  },
  // 销毁之前 页面还未销毁
  // 清空定时器和页面监听
  beforeDestroy() {
    console.log("beforeDestroy")
    clearInterval(this.timer)
  },
  methods: {
    //查询是否有就诊人数据
    getPatientListData() {},
    //首页就诊人列表
    getHomePatientListData() {
      const params = {
        yydm: "",
        page: "",
        pageSize: ""
      }
      gjcModel.getHomePatientListData(params).then((res) => {
        this.patientDict = res.data || {}
        this.changeCardActions = []
        const cardList = res.data?.patientCardList || []
        cardList.forEach((item) => {
          let cartDic = {}
          cartDic.jzklxmc = item.jzklxmc 
          cartDic.jzkh = item.jzkh
          cartDic.id = item.patientCardId
          cartDic.patientId = res.data.patientId
          this.changeCardActions.push(cartDic)
        })
        this.$store.commit("user/setPatient", JSON.stringify(res.data))
      })
    },
    changeCardShowAction() {
      if (this.patientDict.patientCardList.length > 1 && this.changeCardshowPopover === false) {
        this.changeCardshowPopover = true
      }else{
        this.changeCardshowPopover = false
      }
    },
    //切换就诊卡
    changeCardAction(action) {
      this.changeCardshowPopover = false
      // console.log("切换就诊卡", action)
      gjcModel
        .getSetDefaultPatientCardData(action.patientId, action.id)
        .then(() => {
          // console.log("切换就诊卡", res)
          this.getHomePatientListData()
        })
    },
    //定时获取消息
    getTimerData() {
      this.timer = setInterval(() => {
        this.getQueryNewestMsgData()
      }, 9000)
    },
    //获取最新消息
    getQueryNewestMsgData() {
      const params = {
        fszt: "1", //发送状态 1 成功 0失败
        size: "3",
        xxlx: "1", //消息类型 1. 站内消息 2. 短信 3. 微信公众号消息
        yydm: "" //医院代码
      }
      this.msgList = []
      gjcModel.getQueryNewestMsgData(params).then((res) => {
        //console.log('获取最新消息', res)
        this.msgList = res.data || []
        if (this.msgList.length > 0) {
          this.showMessage=  true
        }else{
          this.showMessage=  false
        }
      })
    },
    getMsgDetail(item) {
      if(this.rejectMth()){
        return
      }
      this.$router.push({
        path: "/msgList",
        query: {
          id: item.id
        }
      })
    },
    //获取签到详情
    getSignInQueueDetailData() {
      const data = {
        yydm: "",
        page: 1,
        pageSize: "10"
      }
      if(this.rejectMth()){
        return
      }
      gjcModel.getPatientListData(data).then((res) => {
        console.log("获取就诊人列表", res)
        this.patientList = res.data
        if (res.data.length == 0) {
          this.$toast({
            message: "请先添加就诊人"
          })
          this.$nextTick(() => {
            this.$router.push("/patientList")
          })
          return
        }
        this.$router.push("/signIn")
      })
    },
    //跳转页面
    rejectMth(){
      if (!this.$store.getters.token) {
        this.$router.push({
          path:'/wxCode'
        })
        // this.$router.push({
        //   path:'/login'
        // })
        return true
      }
    },
    routeTo(url, type, require) {
      if(require && this.rejectMth()){
        return
      }
      if (url !=='/patientList' &&  require && isEmptyObject(this.patientDict)) {
        this.$toast({
          message: "请先添加就诊人"
        })
        this.$router.push("/patientList")
        return
      }
      if (url==='/accountSettings' && this.rejectMth()) {
        return
      }
      if (type) {
        this.$router.push({
          path: url,
          query: {
            type: type
          }
        })
      } else {
        this.$router.push(url)
      }
    },
    gotoDzfp(){
      window.location.href = 'https://taxgzh.sztaxs.tech/ypt/api/hxDocuments/hospitalSearch'
    },
    //获取报告类型
    getReportType(type) {
      this.$router.push({
        path: "/reportList",
        query: {
          type: type
        }
      })
    },
    onClickHref(type){
      let method = type === '1' ? 'mall':'naokechina'
      let tokenType =  this.$store.getters.token ? '1' : '2'
      let phoneNum = this.$store.getters.phoneNum || '1'
      gjcModel[method](phoneNum,tokenType).then(res=>{
        console.log("res",res)
        window.location.href = res.data
      })
    },
    routerToHome(url){
      this.$router.push(url)
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";

view {
  box-sizing: border-box;
}


.body {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
  background: #F5F8FA;
}

.payment-tips {
  font-size: 26px;
  text-align: center;
  width: 686px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  background: #fef0f0;
  line-height: 50px;
  height: 50px;
  border: 1px solid #ffe6e6;
}

.patient_card {
  position: relative;
  width: 686px;
  height: 116px;
  margin: 24px auto 0;
  margin-bottom: 10px;
  padding: 30px 0;
  border-radius: 12px;
  color: #4f4f4f;
  background: #FFFFFF;
  opacity: 0.88;
  -webkit-box-shadow:0px 5px 15px 0px #e9f1fa ;
  -moz-box-shadow:0px 5px 15px 0px #e9f1fa ;
  box-shadow:0px 5px 15px 0px #e9f1fa ;
  margin-top: -115px;
  .card_name {
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-size: 34px;
    color: #3D3D3D;
    line-height: 48px;
    font-weight: bold;
    letter-spacing: 1px;

    .name_span {
      margin-left: 12px;
    }
    .card-current{
      width: 112px;
      height: 40px;
      background: rgba(30,141,255,0.08);
      border-radius: 4px;
      display: flex;
      justify-content:center;
      align-items: center;
      text-align: center;
      margin-left: 24px;
      line-height: 30px;
      font-size: 22px;
      color: #1E8DFF;
    }
  }

  .card_number {
    display: flex;
    margin-left: 24px;
    margin-top: 24px;
    font-size: 30px;
    color: #4F4F4F;
    line-height: 42px;
    letter-spacing: 1px;
    .number {
      margin-left: 20px;
    }
    .card_span{
      margin-left: 24px;
    }
  }

  .card_change {
    position: absolute;
    top: 64px;
    right: 32px;
    width: 147px;
    height: 47px;
    font-size: 26px;
    color: #1e8dff;
    line-height: 47px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
  }

  img {
    position: absolute;
    top: 21px;
    right: 32px;
    width: 97px;
    height: 97px;
  }
}

.add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 686px;
  height: 116px;
  margin: 24px auto 0;
  margin-bottom: 10px;
  padding: 30px 0;
  border-radius: 12px;
  color: #4f4f4f;
  background: #FFFFFF;
  opacity: 0.88;
  -webkit-box-shadow:0px 5px 15px 0px #e9f1fa ;
  -moz-box-shadow:0px 5px 15px 0px #e9f1fa ;
  box-shadow:0px 5px 15px 0px #e9f1fa ;
  margin-top: -115px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;

  img {
    margin-right: 24px;
    width: 48px;
    height: 48px;
  }

}

.home_message {
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 80px;
  align-items: center;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 24px;
  .swipe_text_icon {
    margin-top: 24px;
  }
  .message-image{
   width:114px;
   height: 26px;
   margin-left: 24px
  }

  .swipe_right_icon {
    margin-top: 24px;
  }

  .message_count {
    width: 500px;
    padding: 0 24px;

    .message_list {
      margin-right: 20px;
    }
  }

  .notice-swipe {
    background-color: #fff;
    height: 80px;
    line-height: 80px;

    .swipe_text {
      margin-top: 1px;
      font-size: 30px;
      line-height: 80px;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.home-in {
  position: relative;
  z-index: 10;
  padding: 12px 32px 24px;
}

.entry-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 40px 16px;
  font-size: @font-size-26;
  color: #3d3d3d;
  text-align: center;
  background: #fff;
  border-radius: 12px;

  .entry-item {
    width: 160px;

    .img {
      display: block;
      margin: 0 auto;
      width: 116px;
      height: 116px;
    }
  }
}

.card-title {
  font-weight: bold;
  font-size: 32px;
  color: #222B31;
  line-height: 45px;
  letter-spacing: 1px;
  padding: 24px 24px 20px;
}

.card-box {
  display: flex;
  // flex-flow: wrap;
  // justify-content: space-between;
  margin-bottom: 24px;
  // padding: 40px 16px 0;
  background: #fff;
  border-radius: 12px;
  z-index: 2;
  flex-direction: column;
.card-name{
  display: flex;
  flex-wrap: wrap;
}
  .item {
    //  width: 120px;
    width: 25%;
    text-align: center;
    margin-bottom: 24px;

    img {
      width: 64px;
      height: 64px;
      display: block;
      margin: 0 auto;
    }
  }
}

.txt {
  text-align: center;
  font-size: 26px;
  color: #4F4F4F;
  line-height: 37px;
  margin-top: 8px;
}

/deep/.van-swipe-item{
  font-size: 24px !important;
}
.hedaer-image{
  width: 100%;
  height: 352px;
}
.card-list{
  padding: 24px 32px;
  .card-list-item{
    padding: 24px 0;
    font-size: 30px;
    color: #6A6D78;
    line-height: 42px;
    border-bottom: 1px solid #E5E5E5;
  }
  .card-list-item:last-child{
    border-bottom: none;
  }
  .card-list-item-selected{
    color: #1E8DFF;
  }
}
.ml-24{ 
  margin-left: 24px;
}
</style>